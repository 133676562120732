import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import store from './store'
import Buefy from 'buefy'
import IdleVue from 'idle-vue'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import '@/assets/scss/main.scss'
import 'buefy/dist/buefy.css'
import './registerServiceWorker'

Vue.use(Buefy)

Sentry.init({
  dsn: 'https://67fcdd9f622d48a38f93550901b3db8b@o131583.ingest.sentry.io/5287669',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  // FIXME: Load this value from an .env file
  idleTime: 1000 * 120
})

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-ZFTNQ6J4WV" }
});

Vue.mixin({
  methods: {
    getEmsDataPropertyText(propKey) {
      return this.$store.getters.GET_EMS_DATA_PROP(propKey)[this.$store.getters['GET_LANG']]
    },
    requireFromAssetsIfLocalSrc(src) {
      if(src.startsWith('http')) 
        return src;
      else
        return require(`@/assets/${src}`);
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
