<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    preventRightClick (e) {
      e.preventDefault()
    }
  }
}
</script>

<style>

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0%;
  text-align: center;
}

html {
  /* override the buefy changes */
  background-color: inherit !important;
  overflow: hidden; 
}

</style>
