const CONST = {
  BASE_URL_SHARE_VIDEO: 'www.continium.nl',
  ENV: {
    PRODUCTION: 'production',
    STAGING: 'staging',
    DEVELOPMENT: 'development',
    VARS: {
      VUE_APP_PUSHER_KEY: 'a6461fa7731587c1108c',
      VUE_APP_API_URL: 'https://api.staging.invention.continium.blockrocker.io/v1',
    }
  },
  API: {
    BACKGROUND: {
      SELECT: '/messages'
    }
  },
  CMS: {
    ROUTE: {
      CONTENT: '/vlog/'
    }
  },
  EVENT: {
    BACKGROUND: {
      SELECTED: 'background-chosen'
    }
  },
  PUSHER: {
    CHANNEL: 'vlog'
  },
  KEY: {
    REQUEST: {
      AUTHORIZATION: 'Authorization',
      BEARER: 'Bearer ',
      ACCEPT: 'Accept',
      CONTENT_TYPE: 'Content-Type',
      APP_KEY: 'Application-Key',
      APP_SECRET: 'Application-Secret',
      ACCESS_CONTROL_ALLOW_ORIGIN: 'Access-Control-Allow-Origin',
      ACCESS_CONTROL_ALLOW_CREDENTIALS: 'Access-Control-Allow-Credentials'
    }
  },
  VALUE: {
    REQUEST: {
      ACCEPT_JSON: 'application/json',
      ACCEPT_MULTIPART: "multipart/form-data",
      ACCESS_CONTROL_ALLOW_ORIGIN: '*',
      ACCESS_CONTROL_ALLOW_CREDENTIALS: 'true'
    }
  }
}
export default CONST
