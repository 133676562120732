import Vue from 'vue'
import Vuex from 'vuex'
import CONST from '@/utils/Constants'
import hard_coded_ems_data from '@/assets/json/APIDataExampleFallback.json'
import { getEmsData } from '@/BackendClient.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    content: {
      'nl': null, 
      'de': null, 
      'en': null
    },
    lang: 'nl',
    uploaded_video_url: CONST.BASE_URL_SHARE_VIDEO,
    video: {
      uploaded: false
    },
    ems_data: {},
  },
  getters: {
    content: state => {
      return state.content[state.lang] ? state.content[state.lang][0] : null
    },
    GET_EMS_DATA_PROP: state => {
      return key => {
        if(key) {
          if(state.ems_data[key]) {
            return state.ems_data[key]['value']
          }else if(hard_coded_ems_data[key]) {
            return hard_coded_ems_data[key]['value']
          }
        }
        return state.ems_data
      }
    },
    GET_LANG: state => state.lang
  },
  mutations: {
    updateContent: (state, payload) => {
      state.content[payload.lang] = payload.data
    },
    UPDATE_UPLOADED_VIDEO_URL(state, uploaded_video_url) {
      state.uploaded_video_url = uploaded_video_url
    },
    SET_LANG(state, newLang) {
      state.lang = newLang;
    },
    SET_EMS_DATA(state, ems_data_comming_from_api) {
      state.ems_data = ems_data_comming_from_api;
    },
    videoIsUploaded(state, value) {
      state.video.uploaded = value;
    }
  },
  actions: {
    async fetchEmsData({ commit }) {
      try {
        // Mock API request, will be replaced with actual API request
        let group_id = process.env.VUE_APP_GROUP_ID
        let responseJson = await getEmsData(group_id)
        let emsData = responseJson.blocks;
        commit('SET_EMS_DATA', emsData);
      }catch(e) {
        console.error(e);
      }
    }
  }
})
