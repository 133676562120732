import axios from 'axios'

const client = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Application-Key': process.env.VUE_APP_APPLICATION_KEY,
        'Application-Secret': process.env.VUE_APP_APPLICATION_SECRET,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
    }
  });


export async function getEmsData(id) {
    const response = await client.get(`/groups/${id}`);
    console.log('getEmsData');
    console.log(response.data);
    return response.data;
}
export async function saveLink(link) {
    const response = await client.post(`/share/media_link`, { 'value': link })
    console.log(response.data)
    return response.data.key
}
export async function getLink(id) {
    const response = await client.get(`/share/media_link/${id}`)
    console.log(response.data)
    return response.data.value
}
export async function createAnalyticsEvent(event_name) {
    let group_id = process.env.VUE_APP_GROUP_ID
    const response = await client.post(`/analytics_event`, {
        "event_name": event_name,
        "group_id": group_id
    })
    console.log(response.data)
    return
}
export async function sendEmail(to, subject, text) {
    const response = await client.post(`/share/email`, {
        "to": to,
        "subject": subject,
        'text': text
    })
    console.log(response.data)
    return response
}
export async function sendSms(to, text) {
    const response = await client.post(`/share/sms`, {
        "to": to,
        "text": text
    })
    console.log(response.data)
    return response
}
