import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/memories_page',
    name: 'memories_page',
    component: () => import('@/views/MemoriesPage.vue')
  },
  {
    path: '/content',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '',
        component: () => import('@/layouts/FixedLayout.vue'),
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import('@/views/Start.vue')
          },
          {
            path: 'watch-video',
            name: 'watch-video',
            component: () => import('@/views/watch-video/watch-video.vue')
          },
        ]
      },
      {
        path: '',
        component: () => import('@/layouts/TimerLayout.vue'),
        children: [
          {
            path: 'choose-background',
            name: 'choose-background',
            component: () => import('@/views/ChooseBackground.vue')
          },
          {
            path: 'share-video',
            name: 'share-video',
            component: () => import('@/views/ShareTheVlog.vue')
          },
          {
            path: 'input-contact-details',
            name: 'input-contact-details',
            component: () => import('@/views/InputContactDetails.vue')
          },
        ]
      },
      {
        path: 'intro',
        name: 'intro',
        component: () => import('@/views/Introduction.vue')
      },
      {
        path: 'record-video',
        name: 'record-video',
        component: () => import('@/views/record-video/record-video.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/LargeScreenLayout.vue'),
    children: [
      {
        path: 'large-screen-background',
        name: 'large-screen-background',
        component: () => import('@/views/LargeScreenBackground.vue')
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
